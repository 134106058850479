
.traks-key {
	border-left: 12px solid black;
	margin-left: 3px;
	margin-bottom: 16px;
	border-radius: 2px;
}

.traks-key.new {
	border-color: green;
}

.traks-key.deleted {
	border-color: red;
}

.traks-lang {
	border-left: 20px solid #aaa;
	margin-left: 3px;
	margin-bottom: 8px;
	border-radius: 2px;
}

.traks-lang-icon {
	margin-left: -10px;
}

.traks-node {
	margin-left: 3px;
	margin-bottom: 4px;
	border-radius: 2px;
	background-color: #ddd;
	padding-left: 0.5em;
	border: 1px solid #bbb;
	user-select: none;
	cursor: pointer;
}

.traks-but-not-a-node-actually {
	cursor: initial;
	background-color: #bdd;
}

.traks-node.selected {
	background-color: #000;
	color: #fff;
}

.traks-node-tag {
	border: 2px dotted black;
	display: inline-block;
}

.editable_text {
	cursor: pointer;
}

.tag {
	font-family: monospace;
	font-size: 0.7em;
	background-color: #000;
	color: #0f0;
	padding: 0.2em;
	border-radius: 2px;
}

.tag.tag_open:before {
	content: "<";
}

.tag.tag_open:after {
	content: ">";
}


.tag.tag_close:before {
	content: "</";
}

.tag.tag_close:after {
	content: ">";
}

.draggie {
	cursor: pointer;
	user-select: none;
	color: white;
	background: black;
	border-radius: 3px;
	display: inline-block;
}

.placeholder-component {
	border: 1px dotted purple;
}

.our-topbar {
	background: #025;
	color: #fff;
}

.treeview-root {
	padding-top: 1em;
	padding-bottom: 1em;
	background-color: #eee;
}

.treeview-root li {
	list-style-type: none;
}

.treeview-root .node {
}

.treeview-root .node.selected {
	background-color: #ccc;
}

.treeview-dragging-source {
}

.treeview-placeholder {
	border-top: 2px dotted red;
}

.treeview-toggle {
	cursor: pointer;
}

.nodelabel-tag {
	font-family: monospace;
	background-color: #aac;
	padding: 0.2em;
	border-radius: 0.2em;
	font-size: 0.7em;
}

.nodelabel-sourcecode {
	font-family: monospace;
	background: black;
	color: #0f0;
	padding: 0.2em;
	border-radius: 0.2em;
	font-size: 0.7em;
}

.keylist .nodelabel-sourcecode {
	background-color: rgba(0,0,0,0.3);
	color: #8f0;
}

.selected .nodelabel-sourcecode {
	background-color: #040;
	color: #0f0;
}

.nodelabel-text {
}

.patched {
	color: red;
}

.adder-btn {
	color: black;
	padding: 0;
	padding-left: 0.5em;
	padding-right: 0.5em;
}

.errors {
	color: red;
}

.link {
	text-decoration: underline;
	color: blue;
	cursor: pointer;
}

.link:link, .link:visited, .link:focus {
	background: yellow;
}

.link:hover {
	background: #ddf;
}

.link:active {
	color: yellow;
}

.noselect {
	user-select: none;
}

.warning {
	color: orange;
}

.helpful-note {
	color: grey;
	font-size: 0.7em;
}

.helpful-note tt {
	color: black;
	background: #ddd;
}

.htmledit textarea {
	width: 100%;
}

.trinfo-editing {
	background: black;
	color: #aff;
}

.live {
	margin-left: 1em;
	background: black;
	color: yellow;
	border-radius: 5px;
	padding-left: 0.2em;
	padding-right: 0.2em;
	font-weight: bold;
}
